import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(to right, #8e2de2, #4a00e0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 100px;
  resize: vertical;
`;

const Button = styled.button`
  background: linear-gradient(to right, #8e2de2, #4a00e0);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.9;
  }
`;

const InsertMessage = () => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const messageData = {
      name: name,
      message: message,
    };
    try {
      await axios.post(
        "https://kiamabackend.mathenoid.com/messages",
        messageData
      );
      setName("");
      setMessage("");
      alert("Message sent successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending the message");
    }
  };

  return (
    <Container>
      <Card>
        <Title>Send a Message</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextArea
            placeholder="Your Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <Button type="submit">Send Message</Button>
        </Form>
      </Card>
    </Container>
  );
};

export default InsertMessage;
